<template>
    <div>
        <v-card class="px-0 py-0">
            <v-card-text class="px-4 py-4">
                <RetryDataLoading :loadable="refinanciamientos" :retry-handler="cargarRefinanciamientos">
                    <EmptyComponent 
                        :empty="!(listaRefinanciamientos.length > 0) && !refinanciamientos.isLoading" 
                        colored
                        text="Este proceso de compra no posee refinanciamientos."
                    >
                        <template v-slot:icon>
                            <v-icon style="font-size: 48px;" color="var(--v-primary-lighten3) !important">mdi-clipboard-list</v-icon>
                        </template>
                        <div>
                            <v-row>
                                <v-col cols="12" md="6" xl="4">
                                    <v-card class="px-4 py-4">
                                        <p class="mb-0 text-h5 font-weight-bold" style="color: var(--v-info-darken2)">
                                            {{ toMoneyFormat(refinanciamientos.data?.monto_requerido, refinanciamientos.isLoading) }}
                                        </p>
                                        <h6 class="text-subtitle-2" style="color: #7a7a7c">Monto requerido a refinanciar</h6>
                                    </v-card>
                                </v-col>

                                <v-col cols="12" md="6" xl="4">
                                    <v-card class="px-4 py-4">
                                        <p class="mb-0 text-h5 font-weight-bold" style="color: var(--v-info-darken2)">
                                            {{ toMoneyFormat(refinanciamientos.data?.monto_certificado, refinanciamientos.isLoading) }}
                                        </p>
                                        <h6 class="text-subtitle-2" style="color: #7a7a7c">Monto certificado</h6>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <template>
                                <h5 class="mt-12 text-subtitle-1 text-uppercase font-weight-bold" style="color: #000000; line-height: 24px;">
                                    <v-icon style="transform: translateY(-2px)">mdi-archive-plus</v-icon>
                                    Refinanciamientos de compra
                                </h5>
                                <v-divider class="mt-1" />
                                <p v-if="refinanciamientos.isLoading" class="mb-0 mt-4 pl-2 text-body-2 text-uppercase font-weight-bold">Cargando refinanciamientos...</p>

                                <v-expansion-panels v-else class="mt-4" v-model="refinanciamientosAbiertos">
                                    <v-expansion-panel v-for="refinanciamiento in listaRefinanciamientos" :key="refinanciamiento.id">
                                        <v-expansion-panel-header v-slot:default class="px-4">
                                            <div class="d-flex flex-column">
                                                <b class="text-h6 font-weight-bold">{{ refinanciamiento.correlativo }}</b>
                                                <p class="mb-0 text-subtitle-2">{{ refinanciamiento.obs.codigo }} - {{ refinanciamiento.obs.nombre }}</p>
                                            </div>
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-divider />
                                            <div class="px-4 py-4">
                                                <div class="d-flex flex-column flex-wrap justify-end flex-md-row align-md-center" style="gap: 16px;">
                                                    <div>
                                                        <dt class="font-weight-bold text-subtitle-2 text-uppercase">Monto requerido</dt>
                                                        <dd class="text-body-2">{{ toMoneyFormat(refinanciamiento.monto_requerido) }}</dd>
                                                    </div>

                                                    <v-divider class="d-none d-md-block" vertical />

                                                    <div>
                                                        <dt class="font-weight-bold text-subtitle-2 text-uppercase">Monto certificado</dt>
                                                        <dd class="text-body-2">{{ toMoneyFormat(refinanciamiento.monto_certificado) }}</dd>
                                                    </div>
                                                </div>

                                                <!-- Certificaciones por cifrado presupuestario -->
                                                <v-expansion-panels class="mt-4 px-0">
                                                    <v-expansion-panel v-slot:default v-for="fondos in refinanciamiento.fondos_refinanciamiento" :key="fondos.id"> 
                                                        <v-expansion-panel-header>
                                                            <div class="d-flex flex-column justify-center">
                                                                <span class="text-body-2 text-uppercase font-weight-bold">
                                                                    {{ `${fondos.financiamiento_unidad.fondo.cifrado_presupuestario} - ${fondos.financiamiento_unidad.fondo.tipo_fondo === 2 ? 'Votado' : 'Proyecto'}` }}
                                                                </span>
                                                                <span class="text-caption text-uppercase font-weight-medium">
                                                                    {{ toMoneyFormat(fondos.monto_certificado) }} certificados de {{ toMoneyFormat(fondos.monto_solicitado) }}
                                                                </span>
                                                            </div>
                                                            <div class="d-flex flex-column flex-wrap justify-end flex-md-row align-md-center" style="gap: 16px;">
                                                                <v-tooltip v-if="verificarSiRefinanciamientoTotalmenteCertificado(fondos)" top>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-icon 
                                                                            v-on="on" 
                                                                            v-bind="attrs"
                                                                            color="success"
                                                                            class="px-2 py-2"
                                                                        >
                                                                            mdi-certificate
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Certificación realizada</span>
                                                                </v-tooltip>
                                                            </div>
                                                        </v-expansion-panel-header>
                                                        <!-- Cifrados -->
                                                        <v-expansion-panel-content class="px-0 py-0">
                                                            <v-divider />
                                                            <div class="px-4 py-4">
                                                                <v-expansion-panels>
                                                                    <v-expansion-panel>
                                                                        <v-expansion-panel-header>
                                                                            <div class="d-flex align-center" style="gap: 8px;">
                                                                                <v-icon>mdi-account-group</v-icon>
                                                                                <span class="text-uppercase text-uppercase text-caption font-weight-medium">
                                                                                    {{ fondos.financiamiento_unidad.unidad.nombre }}
                                                                                </span>
                                                                            </div>
                                                                        </v-expansion-panel-header>
        
                                                                        <!-- Tabla con las certificaciones -->
                                                                        <v-expansion-panel-content>
                                                                            <v-divider />
                                                                            <DataTableComponent 
                                                                                    :headers="tableHeaders"
                                                                                    :items="fondos.certificaciones"
                                                                                    :tiene_paginacion="false"
                                                                                    class="mx-4 my-4"
                                                                                    no-gutters
                                                                                    dense
                                                                                >
                                                                                    <template v-slot:item.monto_certificado="{ item }">
                                                                                        ${{ item.monto }}
                                                                                    </template>
                                                                                                                                    
                                                                                    <template v-slot:item.acciones="{ item }">
                                                                                        <div class="d-flex align-center justify-center py-2" style="gap: 8px;">
                                                                                            <template>
                                                                                                <v-tooltip top>
                                                                                                    <template v-slot:activator="{ on, attrs }">
                                                                                                        <v-btn 
                                                                                                            v-on="on" 
                                                                                                            v-bind="attrs" 
                                                                                                            :loading="visualizacionDocumento.isLoading"
                                                                                                            class="px-5 py-5" 
                                                                                                            icon 
                                                                                                            @click.stop="visualizarDocumentoCertificacion(item)"
                                                                                                        >
                                                                                                            <v-icon>mdi-file-eye</v-icon>
                                                                                                        </v-btn>
                                                                                                    </template>
                                                                                                    <span>Ver documento certificación</span>
                                                                                                </v-tooltip>
                                                                                            </template>
                                                                                        </div>
                                                                                    </template>
                                                                            </DataTableComponent>
                                                                        </v-expansion-panel-content>
                                                                    </v-expansion-panel>
                                                                </v-expansion-panels>
                                                            </div>
                                                        </v-expansion-panel-content>
                                                    </v-expansion-panel>
                                                </v-expansion-panels>
                                            </div>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </template>
                        </div>
                    </EmptyComponent>
                </RetryDataLoading>
            </v-card-text>
        </v-card>

        <PdfModal
            :isOpen.sync="modalVisualizacionAbierta" 
            :source-loadable="visualizacionDocumento"
            filename="documento-certificacion"
        />
    </div>
</template>
<script>
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import DataTableComponent from '@/components/DataTableComponent.vue';
import RetryDataLoading from '@/components/utils/RetryDataLoading.vue';
import EmptyComponent from '@/components/utils/EmptyComponent.vue';
import PdfModal from '@/components/PdfModal.vue';
import { toMoneyFormat } from '@/utils/data';

export default {
    name: 'RefinanciamientosComponent',
    components: { PdfModal, EmptyComponent, RetryDataLoading, DataTableComponent },
    data: () => ({
        refinanciamientos: createLoadable(),
        refinanciamientosAbiertos: null,
        // Visualizacion documento de certificacion
        modalVisualizacionAbierta: false,
        visualizacionDocumento: createLoadable(null),
    }),
    computed: {
        idProcesoCompra() {
            return this.$route.params.idProceso
        },
        listaRefinanciamientos() {
            return this.refinanciamientos.data?.refinanciamientos ?? [];
        },
        tableHeaders() {
            return [
                { text: 'Monto certificado', value: 'monto_certificado', align: 'center', sortable: false },
                { text: 'Acciones', value: 'acciones', align: 'center', sortable: false },
            ];
        },
    },
    methods: {
        // UI
        toMoneyFormat,
        verificarSiRefinanciamientoTotalmenteCertificado(refinanciamiento) {
            return !(refinanciamiento.monto_solicitado - refinanciamiento.monto_certificado > 0);
        },
        // DATA
        async cargarRefinanciamientos() {
            toggleLoadable(this.refinanciamientos);
            const { data } = await this.services.CertificacionRefinanciamientoCompras.cargarCifradosCertificaciones(this.idProcesoCompra);

            setLoadableResponse(this.refinanciamientos, data);
        },
        async visualizarDocumentoCertificacion(certificacion) {
            this.modalVisualizacionAbierta = true;
            toggleLoadable(this.visualizacionDocumento);
            const { data } = await this.services.CertificacionRefinanciamientoCompras.visualizarDocumentoCertificacion(
                this.idProcesoCompra,
                certificacion.id,
            );
            setLoadableResponse(this.visualizacionDocumento, data, { isFile: true });
        },
    },
    created() {
        this.cargarRefinanciamientos();
    },
}
</script>