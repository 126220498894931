<template>
    <v-card style="height: 100%;">
        <v-card-title>
            <div class="d-flex align-center flex-wrap" style="gap: 4px;">
                <v-icon style="transform: translateY(-2px)">mdi-account-group</v-icon>
                <span class="font-weight-medium text-subtitle-1" style="word-break: word-break;">
                    Ganadores
                </span>
            </div>
        </v-card-title>
        <v-divider />
        <v-card-text class="custom-scroll" style="max-height: 500px; overflow: auto;">
            <v-row class="py-4">
                <v-col cols="12" md="12">
                    <DataTableComponent
                        :items="ganadores"
                        :headers="headers"
                        :total_registros="+totalItems"
                        v-models:pagina="options.page"
                        v-models:select="options.per_page"
                        @paginar="paginar"
                    >
                        <template #[`item.monto_adjudicado`]="{ item }">
                            {{ toMoneyFormat(item.monto_adjudicado) }}
                        </template>
                        
                        <template #[`item.accion`]="{ item }">
                            <app-menu-acciones
                                :menu="getAcciones(item)"
                                titulo="Acciones"
                                :elemento="item"
                            ></app-menu-acciones>
                        </template>
                    </DataTableComponent>
                </v-col>
            </v-row>
            <ModalDetalleLotesAdjudicados
                ref="ModalDetalleLotesAdjudicados"
                :id_proceso="this.idProceso"
                :id_proveedor="this.idProveedor"
                :show="showModalDetalle"
                @cerrar="showModalDetalle = false"
            />
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { toMoneyFormat } from '@/utils/data';
import DataTableComponent from '@/components/DataTableComponent.vue';
import ModalDetalleLotesAdjudicados from './modalDetalleLotesAdjudicados.vue';

export default {
    name: 'ContratosGanadores',
    components: {
        DataTableComponent,
        ModalDetalleLotesAdjudicados,
    },
    data: () => ({
        ganadores: [],
        options: {
            page: 1,
            per_page: 5,
        },
        headers: [
            {
                text: "Proveedor",
                value: "go.Proveedor.nombre",
                align: "start",
            },
            {
                text: "Contrato",
                value: "correlativo_contrato",
                align: "start",
            },
            {
                text: "Monto ($)",
                value: "monto_adjudicado",
                align: "center",
            },
            { 
                text: "Acciones", 
                value: "accion", 
                align: "center" 
            },
        ],
        totalItems: 0,
        showModalDetalle: false,
        idProceso: null,
        idProveedor: null,
    }),
    computed: {
        ...mapState("procesoCompraDoc", ["procesoData"]),
    },
    methods: {
        toMoneyFormat,
        async getGanadoresInfo() {
            const {
                data,
                headers: { total_rows },
            } = await this.services.PacProcesos.getProcesoGanadores(
                this.idProceso,
                this.options
            );
            this.totalItems = total_rows;
            this.ganadores = data;
        },
        paginar(paginacion) {
            console.log('paginacion :>> ', paginacion);
            const { pagina, cantidad_por_pagina: registrosPorPagina } = paginacion;

            this.options.page = pagina;
            this.options.per_page = registrosPorPagina;
            this.getGanadoresInfo();
        },
        getAcciones(item) {
            return [
                {
                    nombre: "Visualizar",
                    icono: "mdi-eye",
                    callback2: () => {
                        this.idProveedor = Number(item['go.Proveedor.id']);
                        this.showModalDetalle = true;
                    },
                },
            ];
        },
    },
    created() {
        this.idProceso = Number(this.$route.params.idProceso);
        this.getGanadoresInfo();
    },
    watch: {
        idProveedor(newVal) {
            if (newVal && this.showModalDetalle) {
                if (this.$refs && this.$refs.ModalDetalleLotesAdjudicados) {
                this.$refs.ModalDetalleLotesAdjudicados.FetchDetailLotesAdjudicados(newVal);
                } else {
                console.warn("El modalDetalleLotesAdjudicados no está disponible en $refs.");
                }
            } else {
                console.warn(
                "Aún no se ha seleccionado un proveedor."
                );
            }
        },
    },
};
</script>

<style scoped>
.custom-scroll {
  /* Activa el scroll personalizado */
    overflow-y: auto;
}

/* Estilos para la barra de desplazamiento */
.custom-scroll::-webkit-scrollbar {
    width: 10px;
}

.custom-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
</style>