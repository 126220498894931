<template>
    <div class="px-2">
        <div v-for="categoria in ajustes">
            <h6 class="text-subtitle-1 font-weight-bold text-uppercase" :class="categoria.wrapperClass">{{categoria.titulo }}</h6>
            <v-divider class="mt-2" />
            <EmptyComponent 
                :empty="categoria.items.length === 0" 
                icon="mdi-alert-box" 
                :text="categoria.mensajeVacio"
                class="mt-4"
                colored
            >
                <v-expansion-panels class="mt-4" accordion>
                    <v-expansion-panel
                      v-for="(ajuste, index) in categoria.items"
                      :key="ajuste.id"
                    >
                      <v-expansion-panel-header>
                        <span class="text-subtitle-2 font-weight-bold text-uppercase">{{ categoria.nombreIndividual }} #{{ index + 1 }}</span>
                      </v-expansion-panel-header>
                      <v-divider />
                      <v-expansion-panel-content class="px-0 py-0">
                        <div class="px-4 py-4">
                            <v-row>
                                <v-col cols="12" lg="6">
                                    <dl>
                                        <dt class="font-weight-bold text-body-2">Fecha:</dt>
                                        <dd class="mt-0 text-body-2">{{ formatDate(ajuste.created_at) }}</dd>
                    
                                        <dt class="font-weight-bold text-body-2 mt-3">Creado por:</dt>
                                        <dd class="mt-0 text-body-2">{{ obtenerNombreCompletoPersona(ajuste.Usuario?.Empleado?.Persona ?? {}) ?? '-' }}</dd>
                
                                        <dt class="font-weight-bold text-body-2 mt-3">Plazo anterior:</dt>
                                        <dd class="mt-0 text-body-2">{{ ajuste.edicion.plazo_anterior ? `${ajuste.edicion.plazo_anterior} Dias` : '-' }}</dd>
                
                                        <dt class="font-weight-bold text-body-2 mt-3">Plazo actual:</dt>
                                        <dd class="mt-0 text-body-2">{{ ajuste.edicion.plazo_actual ? `${ajuste.edicion.plazo_actual} Dias` : '-' }}</dd>
                                    </dl>
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <dl>
                                        <dt class="font-weight-bold text-body-2">Cantidad anterior:</dt>
                                        <dd class="mt-0 text-body-2">{{ ajuste.edicion.cantidad_anterior ?? '-' }}</dd>
                
                                        <dt class="font-weight-bold text-body-2 mt-3">Cantidad actual:</dt>
                                        <dd class="mt-0 text-body-2">{{ ajuste.edicion.cantidad_actual ?? '-' }}</dd>
                
                                        <dt class="font-weight-bold text-body-2 mt-3">Monto anterior:</dt>
                                        <dd class="mt-0 text-body-2">{{ toMoneyFormat(ajuste.edicion.monto_anterior) }}</dd>
                
                                        <dt class="font-weight-bold text-body-2 mt-3">Monto actual:</dt>
                                        <dd class="mt-0 text-body-2">{{ toMoneyFormat(ajuste.edicion.monto_actual) }}</dd>
                                    </dl>
                                </v-col>
                            </v-row>
        
                            <div class="mt-6">
                                <h6 class="font-weight-bold text-subtitle-2 text-uppercase secondary--text">
                                    <v-icon color="secondary">mdi-comment-text</v-icon>
                                    Comentario:
                                </h6>
                                <v-divider class="mt-2" />
                                <p class="mt-2 mb-0 text-body-2">{{ ajuste.edicion.comentario ?? '-' }}</p>
                            </div>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </EmptyComponent>
        </div>
    </div>
</template>
<script>
import EmptyComponent from '@/components/utils/EmptyComponent.vue';
import { obtenerNombreCompletoPersona, toMoneyFormat } from '@/utils/data';
import { formatDate } from '@/utils/datetime';

export default {
    name: 'TabAjustesComponent',
    components: { EmptyComponent },
    props: {
        dataProp: { type: Array },
    },
    computed: {
        modificativas() {
            return this.dataProp.filter((ajuste) => ajuste.CtlEdicioncontrato.id === 1);
        },
        prorrogas() {
            return this.dataProp.filter((ajuste) => ajuste.CtlEdicioncontrato.id === 2);
        },
        ajustes() {
            return [
                { titulo: 'Modificativas', nombreIndividual: 'Modificativa', items: this.modificativas, mensajeVacio: 'El contrato no posee modificativas' },
                { titulo: 'Prórrogas', nombreIndividual: 'Prórroga', items: this.prorrogas, mensajeVacio: 'El contrato no posee prórrogas', wrapperClass: 'mt-8' }
            ];
        },
    },
    methods: {
        formatDate,
        toMoneyFormat,
        obtenerNombreCompletoPersona,
    },
}
</script>
<style scoped>
:deep(.v-expansion-panel-content__wrap) {
    padding: 0 !important;
}
</style>