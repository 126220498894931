<template>
  <v-card class="px-4 py-4">
    <v-row>
      <v-col>
        <v-card style="height: 100%;">
          <v-card-title class="d-flex justify-space-between align-center">
              <div class="d-flex align-center flex-wrap" style="gap: 4px;">
                <v-icon style="transform: translateY(-2px)">mdi-information-variant-circle-outline</v-icon>
                <span class="font-weight-medium text-subtitle-1" style="word-break: word-break;">
                    Información
                </span>
              </div>
              <v-chip 
                :color="procesoData?.SeguimientoProceso?.color" style="display: block" class="white--text" label>
                {{ procesoData?.SeguimientoProceso?.nombre }}
              </v-chip>
          </v-card-title>
              
          <v-divider/>

          <v-card-text>
            <div class="px-4 py-4">
              <dl>
                  <dt class="font-weight-bold text-body-2">Nombre del proceso:</dt>
                  <dd>{{ procesoData?.nombre_proceso }}</dd>

                  <dt class="font-weight-bold mt-4 text-body-2">Modalidad de compra:</dt>
                  <dd>{{ procesoData?.FormaContratacion?.nombre }}</dd>

                  <template v-if="!haveRoles(['ROLE_PROVEEDOR'])">
                    <dt class="font-weight-bold mt-4 text-body-2">Técnico asignado:</dt>
                    <dd>
                        {{ procesoData?.Empleado?.Persona?.primer_nombre }}
                        {{ procesoData?.Empleado?.Persona?.segundo_nombre }}
                        {{ procesoData?.Empleado?.Persona?.tercer_nombre }}
                        {{ procesoData?.Empleado?.Persona?.primer_apellido }}
                        {{ procesoData?.Empleado?.Persona?.segundo_apellido }}
                        {{ procesoData?.Empleado?.Persona?.apellido_casada }}
                    </dd>
                  </template>
                
                  <dt class="font-weight-bold mt-4 text-body-2">Mes/año inicio del proceso:</dt>
                  <dd>{{ moment(picker, 'YYYY-MM').format('MM-YYYY') }}</dd>

                  <dt class="font-weight-bold mt-4 text-body-2">Mes/año posible contratación:</dt>
                  <dd>{{ moment(picker2, 'YYYY-MM').format('MM-YYYY') }}</dd>

                  <template v-if="procesoData?.comentario">
                    <dt class="font-weight-bold mt-4 text-body-2">Comentario sobre la Modificativa/Prórroga:</dt>
                    <dd>{{ procesoData?.nombre_proceso }}</dd>
                  </template>
              </dl>
            </div>
              
            <div v-if="haveRoles(['ROLE_UACI', 'ROLE_UACI_TECNICO', 'ROLE_DIRECCION_COMPRAS', 'ROLE_TECNICO_DIRECCION_COMPRAS'])"
              style="max-width: 400px;"
            >
              <v-row>
                <v-col cols="12" lg="9">
                  <v-file-input
                    v-model="expediente"
                    outlined
                    dense
                    prepend-icon=""
                    class="w-100"
                    label="Expediente de proceso"
                    prepend-inner-icon="mdi-paperclip"
                    accept="application/pdf"
                    style="min-width: 250px;" 
                    :error-messages="expedienteError"
                    @blur="$v.expediente.$touch()" 
                    @input="$v.expediente.$touch()">
                  </v-file-input>
                </v-col>

                <v-col cols="12" lg="3">
                  <v-btn 
                    block 
                    class="py-5"
                    style="min-width: 120px;"
                    :color="documentos.length > 0 ? 'success' : 'secondary'" 
                    @click="cargar()" 
                  >
                    {{ documentos.length > 0 ? 'Editar' : 'Cargar' }}
                  </v-btn>
                </v-col>
              </v-row>

              <v-progress-linear
                v-if="showUploadProgress"
                v-model="uploadProgress"
                class="mb-4"
                color="secondary"
                height="25"
                rounded
              >
                <template v-slot:default="{ value }">
                  <p class="text-center white--text mb-0">{{ value }} %</p>
                </template>
              </v-progress-linear>

              <v-card v-if="documentos.length > 0" 
                @click="descargarArchivo" 
                class="elevation-2" 
                style="cursor: pointer;"
              >
                <v-card-text class="text-center py-2">
                  <p class="mb-0 secondary--text">
                    <v-icon color="secondary">mdi-download</v-icon> Descargar expediente de proceso
                  </p>
                </v-card-text>
              </v-card>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col v-if="[5, 80, 81].includes(procesoData?.SeguimientoProceso?.id)">
        <ContratosGanadores/>
      </v-col>

    </v-row>
  </v-card>
</template>
<script>
import LegacyValidations from "@/utils/legacy-validations";
import { mapState } from "vuex";
import moment from "moment";
import ContratosGanadores from "./ContratosGanadores.vue";

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  name: "InfoGeneralComponent",
  components: { ContratosGanadores },
  data: () => ({
    expediente: null,
    documentos: [],
    showUploadProgress: false,
    uploadProgress: 0,
  }),
  validations: {
    expediente: {
      fileTypeValid,
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(),
    },
  },
  computed: {
    ...mapState("procesoCompraDoc", ["procesoData"]),
    picker() {
      return moment(this.procesoData?.fecha_inicio_proceso).format("YYYY-MM");
    },
    picker2() {
      return moment(this.procesoData?.fecha_contratacion).format("YYYY-MM");
    },
    expedienteError() {
      const errors = [];
      if (!this.$v.expediente.$dirty) return errors;
      !this.$v.expediente.fileTypeValid &&
        errors.push("Solo se permiten archivos PDF");
      !this.$v.expediente.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);
      !this.$v.expediente.fileNameValid &&
        errors.push("El nombre del archivo no debe contener caracteres especiales");
      return errors;
    },
  },
  methods: {
    async downloadCdp() {

      const { status, data, headers } =
        await this.services.Usuarios.descargarTitulo({
          ruta: this.procesoData.documento_cdp,
          disk: "procesos",
        })

      if (status === 200) {
        const blob = new Blob([data], {
          type: headers["content-type"],
        });

        const file = new File(
          [blob],
          `tituloNombramiento_${this.procesoData.id}`,
          {
            type: headers["content-type"],
          }
        );

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = "";
        link.click();
      }


    },
    async getExpedienteProceso() {
      let response2 = await this.services.PacProcesos.obtenerDocumentosProceso(
        this.$route.params.idProceso,
        5000,
      );

      if (response2?.status === 200) {
        this.documentos = response2?.data
        this.documento_cargado = this.documentos[0]?.ruta_documento
      }
    },
    async cargar() {
      try {
        this.$v.$touch()

        const formData = new FormData()
        formData.append("documento", this.expediente)
        formData.append("nombre", "Expediente de proceso")
        formData.append("id_proceso", this.$route.params.idProceso)
        formData.append("id_tipo_documento", 5000)
        formData.append("folder", "expediente_proceso")

        this.showUploadProgress = true;
        const response = await this.services.PacProcesos.guardarDocumentosProceso(
          formData,
          onUploadProgress => {
            this.uploadProgress = Math.round(
              (onUploadProgress.loaded / onUploadProgress.total) * 100
            );
          }
        )

        if (response.status === 200) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Documento cargado exitosamente"
          })
          this.$emit('getProceso');
          this.expediente = null;
          this.$v.$reset();
          this.getExpedienteProceso()
          this.showUploadProgress = false;
          this.uploadProgress = 0;
        }

      } catch (error) {
        if (this.$v.$invalid) {
          if (this.expediente === null) {
            this.temporalAlert({
              show: true,
              type: "error",
              message: "Debe de subir un documento"
            })
          }
          }

        this.uploadProgress = 0;
        this.showUploadProgress = false;
      } finally {
      }

    },
    async descargarArchivo() {

      const response = await this.services.PacProcesos.descargarDocumentoProceso({
        ruta: this.documento_cargado
      })
        .catch(() => {
          })
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], this.documentos[0].nombre_documento, {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "";
      link.click();

    },
  },
  created() {
    this.getExpedienteProceso()
  },
};
</script>
