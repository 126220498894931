<template>
  <v-dialog v-model="show" width="1400" scrollable persistent>
    <v-card>
      <v-card-title class="d-flex align-center">
        <v-icon size="28" color="subastaBlue"> mdi-information-outline </v-icon>
        <span class="subastaBlue--text text-h6 mx-2"
          >Detalles de los Lotes Adjudicados</span>

        <v-spacer></v-spacer>
        <v-btn icon @click.stop="cerrarModal" class="close-btn">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
            :headers="headers"
            :items="lotesAdj"
            :items-per-page="-1"
            hide-default-footer
        >
          <template #[`item.gsp.cantidad`]="{ item }">
            {{ Number(item['gsp.cantidad']) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "modalDetalleLotesAdjudicados",
  data: () => ({
    show_modal: false,
    lotesAdj: [],
    headers: [
      {
        text: "Lote",
        value: "gsp.spo.po.ProcesoCompra.LotesProcesos.nombre",
        width: "80%",
        align: "center",
      },
      {
        text: "Cantidad",
        value: "gsp.cantidad",
        width: "20%",
        align: "center",
      },
    ],
  }),
  methods: {
    async cerrarModal() {
      this.$emit("cerrar");
    },
    
    async FetchDetailLotesAdjudicados(id_proveedor = null) {
      const {status, data} = await this.services.PacProcesos.getLotesAdjudicados(
        this.id_proceso,
        id_proveedor ?? this.id_proveedor,
      );
      this.lotesAdj = data;
    },
  },
  props: {
    id_proceso: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
    },
    id_proveedor: {
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > thead
      > tr:last-child
      > th.sortable
  ) {
  background: transparent !important;
}

:deep(.theme--light.v-data-table > .v-data-table__wrapper > table) {
  border: 1px solid #175efb !important;
}

:deep(.v-data-table-header tr th span) {
  color: #175efb !important;
}

:deep(
    .theme--light.v-data-table
      .v-data-table-header
      th
      .v-data-table-header__icon
  ) {
  color: #175efb !important;
}

:deep(
    .theme--light.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr:last-child
      > td
  ) {
  background: transparent !important;
  border-top: 1px solid #175efb !important;
}
</style>
